<template>
    <el-dialog
            :before-close="cancelBtn"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            center
            title="考试数据共享"
            top="0"
            width="520px"
    >
        <div class="info_2">
            是否授权THUSSAT项目组将你的考试数据共享至所选志愿高校？
        </div>
        <span class="dialog-footer" slot="footer">
<!--      <el-button @click="cancelBtn()" class="cancel">【暂不需要】</el-button>-->
      <el-button @click="confirm()" class="confirm"
      >去授权</el-button
      >
    </span>
    </el-dialog>
</template>

<script>
    export default {
        name: "share",
        props: {
            shareDialogFu: Boolean,
            showDialogFu: Number,
        },
        data() {
            return {
                dialogVisible: this.shareDialogFu,
                showNum: this.showDialogFu,
            };
        },
        methods: {
            confirm() {
                this.cancelBtn();
                this.$router.push({
                    path: "/Info/changepersonalInfo"
                })
                window.localStorage.setItem('isShare', '1')
            },
            // 取消,关闭弹框，修改父组件的值
            cancelBtn() {
                this.dialogVisible = false;
                this.$emit("closeShareDialog", this.dialogVisible, this.showNum);
            },
        },
        watch: {
            shareDialogFu() {
                this.dialogVisible = this.shareDialogFu;
            },
            showDialogFu() {
                this.showNum = this.showDialogFu;
            },
        },

    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/dialog";

</style>

import { request } from "./network";
//获取考试列表
export function getExamList(data) {
  return request({
    method: "post",
    url: "/web/exam/lists",
    data,
  });
}

//获取报名状态
// export function getApplyStatus(data) {
//     return request({
//         method: 'post',
//         url: '/web/exam/Liststatus',
//         data
//     })
// }

//考试详情
export function ExamDetail(data) {
  return request({
    method: "post",
    url: "/web/exam/info",
    data,
  });
}
//判断是否需要弹窗
export function orderAlert(data) {
  return request({
    method: "post",
    url: "/web/exam/orderAlert",
    data,
  });
}

// 获取准考证PDF
export function getZkzPdf(data) {
  return request({
    method: "post",
    url: "/web/download/getZkzPdf",
    data,
  });
}
// 获取准考证PDF
export function downloadZkzPdf(params) {
  return request({
    method: "get",
    url: "/web/download/download",
    params,
  });
}
// 获得考试资料Url
export function downloadInfo(params) {
  return request({
    method: "get",
    url: "/web/download/downloadInfo",
    params,
  });
}

<template>
    <el-dialog
            title="查看成绩"
            :visible.sync="dialogVisible"
            width="520px"
            top="0"
            :before-close="cancelBtn"
            :close-on-click-modal="false"
    >
        <!-- 基础性测试/诊断性测试/学科能力测试： -->
        <div class="info_2">
            <!--<span v-if="objStatus.is_basis==1"><strong>基础报告</strong>侧重总分和分科成绩在全国、全省、全校的排名，题型及知识点的得分。全面分析各科成绩。</span>-->
            <span v-if="objStatus.is_basis==1"><strong>基础报告</strong>展现总分、分科成绩及得分在全国的百分比排名。</span>
            <span v-if="objStatus.is_divine==1"><strong>预测报告</strong>侧重破解新高考选科赋分难题、预测省百分比排名、预测强基计划入围概率。</span>
            <span v-if="objStatus.is_course==1"><strong>选科报告</strong>对学生预选科目进分析，推测选科在全省百分比排名。对学生选科有指导价值。</span>
        </div>

        <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="downloadInfo(1)" v-if="objStatus.is_basis==1">基础报告</el-button>
      <el-button class="confirm" @click="downloadInfo(2)" v-if="objStatus.is_divine==1">预测报告</el-button>
      <el-button class="confirm" @click="downloadInfo(3)" v-if="objStatus.is_course==1">选科报告</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import {downloadInfo} from "r/index/examlist";

    export default {
        name: "ViewReport",
        props: {
            reportDialogFu: Boolean,
            objStatus: Object
        },
        data() {
            return {
                dialogVisible: this.reportDialogFu,
            };
        },
        methods: {
            // 获得考试资料Url
            async downloadInfo(type) {
                const obj = {
                    exam_uuid: window.localStorage.getItem("exam_uuid"),
                    type: type,
                    pay_way: 1,
                };
                const {data: res} = await downloadInfo(obj);
                console.log(res);
                if (res.code !== 200) return this.$message.error(res.message);
                if (res.data.is_pay == 0) {
                    let obj = {
                        code_url: res.data.config.code_url,
                        type: type
                    }
                    this.dialogVisible = false;
                    this.$emit("closeReportDialog", this.dialogVisible, obj);
                } else {
                    this.cancelBtn();
                    window.open( res.data.url, '_blank');
                    // let url = 'http:' + res.data.url;
                    // window.open(url, '_blank');
                }
            },
            // 取消,关闭弹框，修改父组件的值
            cancelBtn() {
                this.dialogVisible = false;
                this.$emit("closeReportDialog", this.dialogVisible);
            },
        },
        watch: {
            reportDialogFu() {
                this.dialogVisible = this.reportDialogFu;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../../assets/scss/dialog";

    ::v-deep .el-dialog {
        .el-dialog__footer {
            padding: 30px 40px 30px 40px;
            text-align: center;

            .el-button {
                width: 120px;
                height: 40px;
            }

            .cancel {
                margin-right: 0;
                color: #ffffff;
                border-color: #8b2d87;
                background-color: #8b2d87;
            }

            .confirm {
                margin-left: 30px;
            }
        }
    }
</style>

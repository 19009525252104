<template>
  <el-dialog
    title="考试报名"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    @open="theTime()"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_2" v-if="orderType == 1">
      你有尚未完成的考试报名，请于规定时间内完成，否则预留的考位将被取消。
    </div>
    <!-- <div class="info_2" v-if="orderType == 1">
      你有尚未完成的考试报名，请于<span> {{ remainTime }} </span>内完成，否则预留的考位将被取消。
    </div> -->
    <div class="info_2" v-if="orderType == 2">
      由于你未在规定时间内完成考试报名缴费，系统为你预留的考位已被取消。
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">稍后查看</el-button>
      <el-button class="confirm" @click="confirm()"
        ><span v-if="orderType == 1">立即前往</span
        ><span v-if="orderType == 2">查看详情</span></el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { djTime } from "s/time.js";
export default {
  name: "OrderAlert",
  props: {
    orderDialogFu: Boolean,
    orderTypeFu: [Number, String],
    endTimeFu: [Number, String],
  },
  data() {
    return {
      remainTime: "", // 剩余时间
      timer: false, // 倒计时计时器
      endTime: this.endTimeFu,
      orderType: this.orderTypeFu,
      dialogVisible: this.orderDialogFu,
    };
  },
  methods: {
    // 打开弹框，赋值，定时器
    theTime() {
      this.orderType = this.orderTypeFu
      if (this.orderType == 1) {
        this.endTime = this.endTimeFu
        this.remainTime = djTime(this.endTime);
        this.timer = setInterval(() => {
          if (this.endTime <= 1) {
            clearInterval(this.timer);
            return (this.orderType = 2);
          }
          this.endTime -= 1;
          this.remainTime = djTime(this.endTime);
        }, 1000);
      }
    },
    confirm() {
      this.cancelBtn();
      this.$router.push({
        name: "OrderList",
      });
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeOrderDialog", this.dialogVisible);
    },
  },
  watch: {
    orderDialogFu() {
      this.dialogVisible = this.orderDialogFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
.info_2 {
  span {
    font-size: 16px;
    color: #ee7602;
    font-family: PingFangSC-Regular;
  }
}
</style>

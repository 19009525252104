<template>
    <div id="main">
        <!--顶部提示消息区域 TACA_828显示-->
        <div class="header_notice" v-if="headerNotice">
            <el-main>
                <div class="header_notice_info">
                    <img src="@/assets/images/notice.png"/>
                    <div class="header_notice_txt">
                        2021-2022学年TACA综合测试现已开放认证成绩单，可用于2022年清华大学丘成桐数学科学领军人才计划初评重要凭证。关注“THUSSAT服务号”，点击菜单【服务】—【查询及认证】申请认证成绩单。
                    </div>
                    <el-button @click="viewRecord(false)" type="text">立即申请<i class="el-icon-arrow-right"></i>
                    </el-button>
                </div>
            </el-main>
        </div>

        <div class="header">
            <div class="box">
                <div class="header_txt">考试列表</div>
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">
                        考试列表
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <el-main>
            <div class="table-list">
                <div class="info_txt">提醒：THUSSAT服务号同步支持考试报名</div>
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column label="考试名称" prop="name" width="180">
                    </el-table-column>
                    <el-table-column label="考试时间" prop="execute_time" width="150">
                    </el-table-column>
                    <!--<el-table-column prop="exam_status_name" label="考试状态" width="150">
                        <template slot-scope="scope">
                            <span v-if="scope.row.exam_status == 1">考试未上架</span>
                            <span v-if="scope.row.exam_status == 2">即将开放</span>
                            <span v-if="scope.row.exam_status == 3">开放报名</span>
                            <span v-if="scope.row.exam_status == 4">报名结束等待准考证</span>
                            <span v-if="scope.row.exam_status == 5">学生可查看下载准考证</span>
                            <span v-if="scope.row.exam_status == 6">考试中</span>
                            <span v-if="scope.row.exam_status == 7" style="color: #ee7602">分数核算中</span>
                            <span v-if="scope.row.exam_status == 8" style="color: #563279">考试成绩公布</span>
                            <span v-if="scope.row.exam_status == 9">考试下架</span>
                        </template>
                    </el-table-column>-->
                    <el-table-column label="考试状态"
                                     prop="exam_status_name"
                                     width="150"
                    ></el-table-column>
                    <el-table-column label="报名状态" width="150">
                        <template slot-scope="scope">
                            <span style="color: #ee7602" v-if="scope.row.status == 0">未报名</span>
                            <span style="color: #8b2d87" v-if="scope.row.status > 0 && scope.row.status < 4"
                            >报名中</span>
                            <span style="color: #563279"
                                  v-if="scope.row.status >= 4 && scope.row.status < 6"
                            >待支付</span>
                            <span v-if="scope.row.status == 6">报名成功</span>
                            <span style="color: #563279"
                                  v-if="scope.row.status == 8 || scope.row.status == 7"
                            >取消报名</span>
                            <span style="color: #563279" v-if="scope.row.status == 99"
                            >候补中</span>
                        </template>
                    </el-table-column>
                    <!--
                        type--考试的类型
                            1-导表B端【诊断性】
                            2-导表B端【基础性】
                            3-邀请码前置且必填【4.28 适应性】
                            4-邀请码非必填【TACA】自己报名
                            5-简测 自己报名
                            6-学生自主报名【诊断性】
                            7-导入学生（美院报名） —类似TACA
                    -->
                    <el-table-column label="操作" min-width="302px">
                        <template slot-scope="scope">
                            <el-button
                                    @click="handleEdit(scope.row)"
                                    size="mini"
                                    style="background-color: #8b2d87"
                                    v-if="scope.row.exam_status >= 2 && scope.row.exam_status < 8 && scope.row.type != 7"
                            >查看须知
                            </el-button>
                            <!--
                                visible_type
                                0 所有考生正常，
                                1 没有通过的考生 能看到考试介绍，无法报名
                                2 没有通过的考生看不到考试介绍，已报名
                            -->
                            <el-tooltip :content="scope.row.visible_tips"
                                        effect="dark"
                                        placement="top"
                                        v-if="scope.row.visible_type == 1">
                                <el-button
                                        :disabled="scope.row.exam_status < 3 || isDisable"
                                        size="mini"
                                        style="background-color: #CCCCCC"
                                        v-if="scope.row.exam_status == 3 &&
                                    (scope.row.type == 4||scope.row.type == 6||scope.row.type == 7) &&
                                    ((scope.row.status >= 0 && scope.row.status < 6) ||
                                    scope.row.status == 8 || scope.row.status == 7 ||
                                    scope.row.status == 99)"
                                >
                                    <span v-if="scope.row.type == 7">进入考试</span>
                                    <span v-else>立即报名</span>
                                </el-button>
                            </el-tooltip>
                            <el-button
                                    :disabled="scope.row.exam_status < 3 || isDisable"
                                    @click="handleApply(scope.row)"
                                    size="mini"
                                    style="background-color: #563279"
                                    v-if="scope.row.exam_status == 3 &&
                                    (scope.row.type == 4||scope.row.type == 6||scope.row.type == 7) &&
                                    ((scope.row.status >= 0 && scope.row.status < 6) ||
                                    scope.row.status == 8 || scope.row.status == 7 ||
                                    scope.row.status == 99) && scope.row.visible_type != 1"
                            >
                                <span v-if="scope.row.type == 7">进入考试</span>
                                <span v-else>立即报名</span>
                            </el-button>
                            <!--<el-button
                                    size="mini"
                                    style="background-color: #563279"
                                    v-if="scope.row.exam_status == 3 &&
                                    (scope.row.type == 4||scope.row.type == 6||scope.row.type == 7) &&
                                    (scope.row.status == 8 || scope.row.status == 7)"
                                    :disabled="scope.row.exam_status < 3 || isDisable"
                                    @click="handleApply(scope.row)"
                            >立即报名
                            </el-button>-->
                            <el-button
                                    @click="handleInfo(scope.row)"
                                    size="mini"
                                    style="background-color: #563279"
                                    v-if="(scope.row.exam_status >= 3 &&scope.row.exam_status < 9) &&
                                    (scope.row.type == 7) &&
                                    (scope.row.status == 6)"
                            >
                                <span>查看预定信息</span>
                            </el-button>
                            <el-button
                                    @click="handleInfo(scope.row)"
                                    size="mini"
                                    style="background-color: #563279"
                                    v-if="(scope.row.exam_status >= 3 &&scope.row.exam_status < 9) &&
                                    (scope.row.type == 4||scope.row.type == 6) &&
                                    (scope.row.status == 6)"
                            >
                                <span>查看报名信息</span>
                            </el-button>
                            <!--test=1 正式库的测试数据，无视考试状态，开放下载准考证、查看成绩、查看认证成绩单-->
                            <el-button
                                    :disabled="scope.row.exam_status > 5 && scope.row.exam_status < 8"
                                    @click="downloadZKZ(scope.row)"
                                    size="mini"
                                    style="background-color: #563279"
                                    v-if="(scope.row.test==1||(scope.row.exam_status >= 5 && scope.row.exam_status < 8) &&
                                    scope.row.show_ticket == 1) &&
                                    scope.row.status == 6"
                            >下载准考证
                            </el-button>
                            <el-button
                                    @click="viewReport(scope.row)"
                                    size="mini"
                                    style="background-color: #563279"
                                    v-if="(scope.row.test==1||scope.row.exam_status == 8)&&
                                    scope.row.status == 6 &&
                                    (scope.row.is_basis == 1 ||
                                    scope.row.is_course == 1 ||
                                    scope.row.is_divine == 1)"
                            >查看成绩
                            </el-button>
                            <el-button
                                    @click="viewRecord(scope.row)"
                                    size="mini"
                                    style="background-color: #563279; width: 120px"
                                    v-if="(scope.row.test==1||scope.row.exam_status == 8) &&
                                    scope.row.is_report == 1 &&
                                    scope.row.status == 6"
                            >查看认证成绩单
                            </el-button>

                            <el-tooltip content="该考试只面向报考清华大学“艺术类（美术学院）招生项目”的学生"
                                        effect="dark"
                                        placement="top"
                                        v-if="(scope.row.exam_status >=4 &&scope.row.exam_status < 9) &&
                                    (scope.row.type == 7) &&
                                    (scope.row.status != 6)">
                                <span style="color: #CCCCCC; padding: 3px 0 0 0;cursor: pointer;"
                                >预定已截止</span>
                            </el-tooltip>
                            <span style="color: red; padding: 3px 0 0 0"
                                  v-if="scope.row.exam_status == 2"
                            >{{formatHandle(scope.row.enroll_start_time)}}正式开放</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-main>

        <!-- 认证成绩单弹框 -->
        <record-paper
                :recordDialogFu="recordDialogFu"
                @closeRecordDialog="closeRecordDialog"
        ></record-paper>
        <!-- 查看成绩弹框（报告） -->
        <view-report
                :objStatus="objStatus"
                :reportDialogFu="reportDialogFu"
                @closeReportDialog="closeReportDialog"
        ></view-report>
        <!-- 是否有下订单弹框 -->
        <order-alert
                :endTimeFu="endTimeFu"
                :orderDialogFu="orderDialogFu"
                :orderTypeFu="orderTypeFu"
                @closeOrderDialog="closeOrderDialog"
        ></order-alert>
        <!-- 安全承诺书-->
        <safety-commit
                :downloadUrl="downloadUrl"
                :isApplyHome="isApplyHome"
                :safetyDialogFu="safetyDialogFu"
                :titleFu="titleFu"
                @closeSafetyDialog="closeSafetyDialog"
        ></safety-commit>
        <!-- 重要通知弹框 -->
        <import-notice
                :noticeDialogFu="noticeDialogFu"
                @closeNoticeDialog="closeNoticeDialog"
        ></import-notice>
        <!--支付弹框-->
        <confirm-sign-up
                :confirmSignUpDialogFu="confirmSignUpDialogFu"
                :examUuid="examUuid"
                :qrFormDataFu="qrFormDataFu"
                @closeCFSUDialog="closeCFSUDialog"
        ></confirm-sign-up>
        <!--下载准考证-申请延期提醒-->
        <post-pone-dialog
                :postPoneDialogFu="postPoneDialogFu"
                @closePPDialog="closePPDialog"
        ></post-pone-dialog>
        <!-- 成绩共享授权弹框 -->
        <share-dialog
                :shareDialogFu="shareDialogFu"
                :showDialogFu="showDialogFu"
                @closeShareDialog="closeShareDialog"
        ></share-dialog>
    </div>
</template>

<script>
    import RecordPaper from "c/index/examList/RecordPaper";
    import OrderAlert from "c/index/examList/OrderAlert";
    import ViewReport from "c/index/examList/ViewReport";
    import SafetyCommit from "c/index/examList/SafetyCommit";
    import importNotice from "c/index/examList/importNotice"; //重要通知弹框
    import ConfirmSignUp from "c/index/examList/ConfirmSignUp";
    import postPoneDialog from "c/index/examList/postPoneDialog";
    import shareDialog from "c/index/examList/shareDialog";
    import {downloadInfo, downloadZkzPdf, getExamList, getZkzPdf, orderAlert} from "r/index/examlist";
    // import {signUpInit} from "r/index/signUp";
    import {signUpInitReq} from "common/request";
    import {formatDate} from "s/time";
    import {getStep1} from "r/index/signUp";

    export default {
        name: "ExamLists",
        data() {
            return {
                tableData: [],
                recordDialogFu: false, // 控制认证成绩单弹框的显示与隐藏
                endTimeFu: "", // 订单结束时间
                orderTypeFu: 1, // 订单状态（1-待支付订单，2-超时订单）
                orderDialogFu: false, // 控制是否有下订单弹框的显示与隐藏
                objStatus: {},
                reportDialogFu: false, // 控制查看成绩弹框（报告）的显示与隐藏
                isShow: false,
                safetyDialogFu: false, // TACA_828控制安全承诺弹框的显示与隐藏
                titleFu: '注意事项',
                isApplyHome: 0,// TACA_828报备/安全承诺书/延期退费  0-啥都没有  1-已经申请了居家考,审核通过  2-审核没通过/从未申请(下载普通的)  3-2022.5.21线下考  4-2022.5.21延期退费  5-2022.521天津考生
                downloadUrl: "",// 下载连接
                isDisable: false, // 禁用 false-不禁用，true-禁用
                noticeDialogFu: false,// TACA_828控制重要通知弹框的显示与隐藏
                headerNotice: false,// TACA_828是否显示顶部提示消息
                examUuid: "",
                qrFormDataFu: {},// 生成支付二维码的数据
                confirmSignUpDialogFu: false, // 控制确认报名弹框的显示与隐藏
                postPoneDialogFu: false, // 控制下载准考证-申请延期提醒弹框的显示与隐藏
                isSelectData: {}, // 当前选中的考试info
                isWishinfo: false,//是否弹出share成绩共享授权弹框
                shareDialogFu: false,//成绩共享授权弹框
                showDialogFu: null,//0-点击稍后完善-查看成绩弹框，1-点击稍后完善-认证成绩单弹框
                objRow: {}
            };
        },
        created() {
            this.getExamlist();
            this.orderAlert();
            // // 打开重要通知弹框
            // this.showNotice();
            this.getAccountList();
        },
        methods: {
            //获取个人信息
            getAccountList() {
                getStep1()
                    .then((res) => {
                        console.log(res, "res");
                        if (res.data.code === 200) {
                            if (res.data.info.share!=0) {
                                //是否弹出share成绩共享授权弹框
                                this.isWishinfo = true;
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            // 子组件触发，关闭成绩共享授权弹框
            closeShareDialog(val, num) {
                this.shareDialogFu = val;
                if (num == 0) {
                    // 打开查看成绩单
                    this.reportDialogFu = true;
                    this.objStatus = {
                        is_basis: this.objRow.is_basis,
                        is_course: this.objRow.is_course,
                        is_divine: this.objRow.is_divine,
                    };
                } else {
                    // 认证成绩单
                    this.recordDialogFu = true;
                }
            },
            // 子组件触发，关闭下载准考证-申请延期提醒弹框
            closePPDialog(val) {
                this.postPoneDialogFu = val;
            },
            formatHandle(val) {
                return formatDate(val * 1000)
            },
            // 子组件触发，关闭确认报名弹框
            closeCFSUDialog(val) {
                this.confirmSignUpDialogFu = val;
            },
            // 子组件触发，关闭安全承诺弹框
            closeSafetyDialog(val, type) {
                this.safetyDialogFu = val;
                if (type) {
                    this.downloadZKZRequest();
                }
            },
            // 子组件触发，关闭订单弹框
            closeOrderDialog(val) {
                this.orderDialogFu = val;
            },
            // 子组件触发，关闭认证成绩单弹框
            closeRecordDialog(val) {
                this.recordDialogFu = val;
            },
            // 子组件触发，关闭查看成绩弹框
            closeReportDialog(val, type) {
                this.reportDialogFu = val;
                if (type) {
                    type['msgInfo'] = "考生，你好！你的本次考试尚未完成缴费。请完成缴费后继续查看。"
                    this.qrFormDataFu = type;
                    this.confirmSignUpDialogFu = true;
                    this.isDisable = false;

                } else {
                    this.isDisable = false;
                }
            },
            // 子组件触发，关闭查看成绩弹框
            closeNoticeDialog(val) {
                this.noticeDialogFu = val;
            },
            // 重要通知弹窗
            showNotice() {
                let studentId = window.localStorage.getItem("student_id");
                let notice = window.localStorage.getItem("exam_notice_" + studentId);
                if (notice && notice == 'true') {
                    return
                }
                this.noticeDialogFu = true;
                window.localStorage.setItem("exam_notice_" + studentId, true);
            },
            // 判断是否需要弹窗
            async orderAlert() {
                const {data: res} = await orderAlert();
                console.log(res);
                if (res.code !== 200) return;
                this.endTimeFu = 900;
                this.orderTypeFu = res.type;
                this.orderDialogFu = true;
            },
            // 查看认证成绩单
            viewRecord(row) {
                // console.log(row)
                if (row) {
                    window.localStorage.setItem("exam_uuid", row.uuid);
                    window.localStorage.setItem("enroll_end_time", row.enroll_end_time);
                }
                if (this.isWishinfo == false) {
                    //未填写志愿高校
                    this.shareDialogFu = true;
                    this.showDialogFu = 1;
                } else {
                    this.recordDialogFu = true;
                }
            },
            // 查看成绩
            async viewReport(row) {
                this.objRow = row;
                if (this.isDisable) {
                    return false;
                }
                this.isDisable = true;
                this.examUuid = row.uuid;
                window.localStorage.setItem("exam_uuid", row.uuid);
                window.localStorage.setItem("enroll_end_time", row.enroll_end_time);
                if (row.uuid == 'TACA20220807') {
                    const obj = {
                        exam_uuid: row.uuid,
                        type: 1,// 1-基础报告
                        pay_way: 1,
                    };
                    const {data: res} = await downloadInfo(obj);
                    if (res.code !== 200) {
                        this.isDisable = false;
                        return this.$message.error(res.message);
                    }
                    if (res.data.is_pay == 0) {
                        let obj = {
                            code_url: res.data.config.code_url,
                            type: 1
                        }
                        obj['msgInfo'] = '考生，你好！你的本次考试尚未完成缴费。请完成缴费后继续查看。';
                        this.qrFormDataFu = obj;
                        this.confirmSignUpDialogFu = true;
                        this.isDisable = false;
                    } else {
                        this.isDisable = false;
                        window.open(res.data.url, '_blank')
                    }
                } else if (this.isWishinfo == false) {
                    //未填写志愿高校
                    this.shareDialogFu = true;
                    this.showDialogFu = 0;
                } else {
                    this.objStatus = {
                        is_basis: row.is_basis,
                        is_course: row.is_course,
                        is_divine: row.is_divine,
                    };
                    this.reportDialogFu = true;
                }
            },
            //获取考试列表
            async getExamlist() {
                this.isShow = true;
                const {data: res} = await getExamList();
                this.isShow = false;
                if (res.code !== 200) return this.$message.error(res.message);
                this.tableData = res.list;
                // // 遍历考试列表，TACA_828 显示顶部提示消息(和查看认证成绩单按钮一起出现)
                // this.tableData.forEach(item=>{
                //   if(item.uuid=="TACA20210828"
                //           &&item.exam_status == 8
                //           && item.is_report == 1
                //           && item.status == 6){
                //       this.headerNotice = true;
                //   }
                // })
            },
            // 查看须知
            handleEdit(row) {
                window.localStorage.setItem("exam_uuid", row.uuid);
                window.localStorage.setItem("enroll_end_time", row.enroll_end_time);
                this.$router.push({name: "examdetails"});
            },
            // 立即报名
            async handleApply(row) {
                if (this.isDisable) {
                    return;
                }
                this.isDisable = true;
                window.localStorage.setItem("exam_uuid", row.uuid);
                window.localStorage.setItem("enroll_end_time", row.enroll_end_time);
                await signUpInitReq(row.uuid);
                this.isDisable = false;
            },
            // 查看报名信息
            handleInfo(row) {
                window.localStorage.setItem("exam_uuid", row.uuid);
                window.localStorage.setItem("order_no", row.order.order_no);
                window.localStorage.setItem("enroll_end_time", row.enroll_end_time);
                this.$router.push({
                    name: "ViewInfo",
                });
            },
            // 下载准考证
            downloadZKZ(row) {
                this.examUuid = row.uuid;
                this.isSelectData = row;
                window.localStorage.setItem("exam_uuid", row.uuid);
                window.localStorage.setItem("enroll_end_time", row.enroll_end_time);
                if (row.uuid == 'TACA20210828' || row.uuid == 'TACA20220807') {
                    this.titleFu = '注意事项';
                    this.isApplyHome = row.is_apply_home;
                    this.downloadUrl = row.download_url;
                    this.safetyDialogFu = true;
                } else if (row.uuid == 'TACA20220423') {
                    // if (row.order && row.order.city_id == 1201) {
                    //     // 天津考生
                    //     this.titleFu = '提示';
                    //     this.isApplyHome = 5;
                    //     this.downloadUrl = "";
                    //     this.safetyDialogFu = true;
                    // }
                    if (row.order_info
                        && row.order_info.show_postpone_status == 1
                        && row.order_info.show_refund_status == 1) {
                        // 显示申请延期按钮、显示退费按钮
                        // this.postPoneDialogFu = true;
                        this.titleFu = '提示';
                        this.isApplyHome = 4;
                        this.downloadUrl = "";
                        this.safetyDialogFu = true;
                    } else {
                        this.titleFu = '注意事项';
                        this.isApplyHome = 3;
                        this.downloadUrl = row.download_url;
                        this.safetyDialogFu = true;
                    }
                } else {
                    this.downloadZKZRequest();
                }
            },
            // 下载准考证请求
            async downloadZKZRequest() {
                let obj = {
                    exam_uuid: window.localStorage.getItem("exam_uuid"),
                    pay_way: 1,
                };
                // 获取准考证PDF
                let {data: res} = await getZkzPdf(obj);
                console.log(res);
                if (res.code !== 200) return this.$message.error(res.message);
                if (res.data.is_pay == 0) {
                    let obj = {
                        code_url: res.data.config.code_url,
                        type: 0,
                        msgInfo: '考生，你好！你的本次考试尚未完成缴费。请完成缴费后继续查看。'
                    }
                    this.qrFormDataFu = obj;
                    this.confirmSignUpDialogFu = true;
                    this.isDisable = false;
                } else {
                    // 下载准考证
                    await this.downloadZkzPdf(res.data);
                }
            },
            // 下载准考证请求2
            async downloadZkzPdf(info) {
                let {data: res} = await downloadZkzPdf({
                    exam_uuid: info.exam_uuid,
                    student_id: info.student_id,
                });
                console.log(res);
                if (res.code !== 200) {
                    this.isDisable = false;
                    return this.$message.error(res.message);
                }
                // console.log(res.url)
                // window.location.href = window.location.origin + "/exam-center/examList/downloadZkz?dataurl=" + res.url;
                window.location.href = window.location.origin + "/examList/downloadZkz?dataurl=" + res.url;
                this.isDisable = false;
            },
        }
        ,
        components: {
            RecordPaper,
            OrderAlert,
            ViewReport,
            SafetyCommit,
            importNotice,
            ConfirmSignUp,
            postPoneDialog,
            shareDialog
        }
        ,
    }
    ;
</script>

<style lang="scss" scoped>
    // 面包屑样式
    @import "a/scss/beradcrumb";
    // 表格样式
    @import "a/scss/table";

    #main {
        width: 100%;
        min-height: 80%;
        overflow: auto;
        background-color: #fff;
        padding-bottom: 30px;

        .header_notice {
            padding: 12px 0;
            background-color: rgba(215, 119, 14, 0.09);
            border: 0px solid rgba(215, 119, 14, 0.09);

            .header_notice_info {
                display: flex;
                flex-flow: row;
                position: relative;
                overflow: hidden;

                img {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 15px;
                    height: 18px;
                }

                .header_notice_txt {
                    flex: 1;
                    overflow: hidden;
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #D7770E;
                    margin-left: 22px;
                }

                .el-button {
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #D7770E;
                    line-height: 14px;
                    padding: 0;
                    margin-left: 20px;
                }
            }
        }

        .header {
            width: 100%;
            height: 160px;
            // margin: 6px auto;
            background-color: #f5f5f5;

            .box {
                max-width: 1300px;
                padding: 45px 50px 0;
                margin: 0 auto;
                display: block;

                .header_txt {
                    font-weight: 400;
                    font-size: 24px;
                    /*padding: 45px 0 0;*/
                }
            }
        }

        .el-main {
            max-width: 1300px;
            margin: 0px auto;
            padding: 0 50px;
        }

        .table-list {
            width: 100%;
            background: #fff;
            // margin-top: 40px;
            // padding: 20px 10px;
            .info_txt {
                height: 14px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #ee7602;
                line-height: 14px;
                margin: 40px 0 20px;
            }
        }
    }

    ::v-deep .el-table {
        margin-top: 0;

        .el-table__row {
            td {
                border-bottom: 1px solid #e5e5e5 !important;
            }
        }
    }
</style>

<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="600px"
            top="0"
            :before-close="cancelBtn"
            :close-on-click-modal="false"
    >
        <!--<div class="info_2">
            1、请下载并仔细阅读《2021年4月24日学科能力综合测试考生考试安全承诺书》，<span>须填写完整并在考试当日同准考证一起携带至考点备查</span>。<br/>
            承诺书下载链接：<a target="_blank" class="download"
                       href="https://studentinfobj.oss-cn-beijing.aliyuncs.com/2021%E5%B9%B44%E6%9C%8824%E6%97%A5%E5%AD%A6%E7%A7%91%E8%83%BD%E5%8A%9B%E7%BB%BC%E5%90%88%E6%B5%8B%E8%AF%95%E8%80%83%E7%94%9F%E8%80%83%E8%AF%95%E5%AE%89%E5%85%A8%E6%89%BF%E8%AF%BA%E4%B9%A6.pdf">点击下载</a>
        </div>
        <div class="info_2">
            2、考点参试人数较多，建议乘坐公共交通工具前往。
        </div>-->

        <!--已经申请了居家考,审核通过-->
        <template v-if="isApplyHome==1">
            <!--<div class="info_2">
                1、报备信息显示你的居住地或考前14日内旅居过的地点存在高风险地区，根据疫情形势及相关防控要求，本次测试须采用“居家考”方式进行。
            </div>-->
            <div class="info_2">
                1、根据疫情形势及相关防控要求，本次测试须采用“居家考”方式进行。
            </div>
            <div class="info_2">
                2、请下载<a target="_blank" class="download" :href="downloadUrl"
            >《2022-2023学年TACA综合测试居家考操作指引》</a>，并按操作指南做好考试相关准备。
            </div>
            <br/>
            <div class="info_2">
                有关“居家考”有任何疑问，可通过邮件或电话向项目组咨询。<br/>
                <!--项目组电话：010-56218127；010-56218263；010-56218232（周一到周五，节假日除外，9:00-18:00）<br/>-->
                项目组电话：010-56218127；010-56218263；010-56218232<br/>
                项目组邮箱：service@thussat.com
            </div>

            <span slot="footer" class="dialog-footer">
                <el-checkbox v-model="checked">我已下载并阅读《2022-2023学年TACA综合测试居家考操作指引》</el-checkbox>
                <el-button :disabled="!checked" class="confirm" @click="confirm()">前往下载准考证</el-button>
            </span>
        </template>
        <!--审核没通过/从未申请(下载普通的)-->
        <template v-if="isApplyHome==2">
            <div class="info_2">
                1、请下载<a target="_blank" class="download" :href="downloadUrl"
            >《2022-2023学年TACA综合测试考生安全承诺书》</a>，须填写完整并在考试当日连同准考证一起携带至考点备查。
            </div>
            <br/>
            <!--<div class="info_2">
                2、根据防疫要求，考点城市在<span>北京市、成都市、广州市、杭州市、合肥市、济南市、昆明市、南昌市、深圳市、沈阳市、乌鲁木齐市、西安市、郑州市、福州市、南宁市</span>的考生，请携带<span>48小时核酸检测纸质报告</span>至考点备查。
            </div>-->

            <span slot="footer" class="dialog-footer">
                <el-checkbox v-model="checked">我已下载并阅读《2022-2023学年TACA综合测试考生安全承诺书》</el-checkbox>
                <el-button :disabled="!checked" class="confirm" @click="confirm()">前往下载准考证</el-button>
            </span>
        </template>
        <!--2022.5.21 线下考试-->
        <template v-if="isApplyHome==3">
            <div class="info_2">
                1、请下载<a target="_blank" class="download" :href="downloadUrl"
            >《2022年5月21日学科能力综合测试考生考试安全承诺书》</a>，须填写完整并在考试当日连同准考证一起携带至考点备查。
            </div>
            <br/>
            <div class="info_2">
                2、根据防疫要求，考生请佩戴口罩，到达考点后配合测量体温，出示<span>当地健康绿码、14天行程码、48小时核酸检测纸质报告</span>至考点备查。配合考点进行相关防疫要求检查，遵守相关规定。
            </div>

            <span slot="footer" class="dialog-footer">
                <el-checkbox v-model="checked">我已下载并阅读《2022年5月21日学科能力综合测试考生考试安全承诺书》</el-checkbox>
                <el-button :disabled="!checked" class="confirm" @click="confirm()">前往下载准考证</el-button>
            </span>
        </template>
        <!--2022.5.21 延期退费-->
        <template v-if="isApplyHome==4">
            <div class="info_2">
                根据疫情形势及相关防控要求，为最大限度保障参试考生的安全及考试的严肃性，项目组决定：<br/>
                为北京市、上海市、吉林省、广东省中山市考生（包括选择该考点城市的外地考生），部分来自中高风险地区或在考试开始前14天有中高风险地区所在城市旅居史的考生，以及其他不符合国家防疫要求的考生安排考试延期至8月TACA综合测试（拟于8月27日举行）或取消报名。<br/>
                请在本系统<span>“查看报名信息”</span>页面选择延期或者取消报名。对于取消报名的考生，项目组会在考试结束五个工作日内发起退费，请注意查收。
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button class="confirm" style="margin-top: 20px;" @click="cancelBtn()">我已知晓</el-button>
            </span>
        </template>
        <!--2022.5.21 天津考生-->
        <template v-if="isApplyHome==5">
            <div class="info_2">
                请于5月19日15:00后登陆确认考试状态。
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button class="confirm" style="margin-top: 20px;" @click="cancelBtn()">我已知晓</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: "SafetyCommit",
        props: {
            safetyDialogFu: Boolean,
            titleFu: [Number, String],
            isApplyHome: [Number, String],
            downloadUrl: [Number, String]
        },
        data() {
            return {
                title: this.titleFu,
                dialogVisible: this.safetyDialogFu,
                checked: false
            };
        },
        methods: {
            confirm() {
                this.checked = false
                this.dialogVisible = false;
                this.$emit("closeSafetyDialog", this.dialogVisible, true);
            },
            // 取消,关闭弹框，修改父组件的值
            cancelBtn() {
                this.checked = false
                this.dialogVisible = false;
                this.$emit("closeSafetyDialog", this.dialogVisible, false);
            },
        },
        watch: {
            safetyDialogFu() {
                this.dialogVisible = this.safetyDialogFu;
            },
            titleFu() {
                this.title = this.titleFu;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../../assets/scss/dialog";

    ::v-deep .el-dialog {
        .info_2 {
            a.download {
                color: #563279;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

                /*font-weight: bold;*/
            }

            span {
                color: #D18635;
            }
        }

        .el-checkbox {
            margin: 20px 0;

            .el-checkbox__input {
                .el-checkbox__inner {
                    border-color: #563279;
                }

            }

            &.is-checked {
                .el-checkbox__input {
                    .el-checkbox__inner {
                        background-color: #563279;
                        border-color: #563279;
                    }
                }

                .el-checkbox__label {
                    color: #563279;
                }
            }

        }

        .el-dialog__footer {
            /*padding: 30px 40px 30px 40px;*/
            padding: 0px 40px 30px 40px;
            text-align: left;

            .el-button {
                width: 100%;
                height: 40px;
            }

            .confirm {
                width: 100%;
                margin-left: 0;
            }

            .el-button.is-disabled {
                opacity: 0.6;
            }
        }
    }
</style>

<template>
  <el-dialog
    title="TACA综合测试重要通知"
    :visible.sync="dialogVisible"
    width="620px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div>
      <div class="info_2 bk" style="padding: 21px 12px; text-indent: 2em">
        受新型冠状病毒肺炎疫情影响，为防止疫情扩散，保障考生安全，原定于2021年8月28日举行的“2021-2022学年TACA综合测试”延期至2021年9月4日举行。项目组后续将视疫情态势变化和相关政策要求另行通知具体考试时间。在此期间，考试报名系统将持续开放，考生可正常进行报名、申请候补和退费。建议考生不要提前安排行程，以免因考试时间调整造成相应损失。
      </div>
    </div>
    <div class="info_2 aa" style="text-indent: 2em">
      请广大师生、家长密切关注THUSSAT微信服务号（“THUSSAT服务”）消息和手机短信通知。如有任何疑问可通过邮件、电话向THUSSAT项目组咨询。
    </div>
    <!-- <div class="info_2">请填写取消报名的理由并点【确认】提交，THUSSAT项目组将在3个工作日内进行处理。</div> -->
    <div class="info_2 aa">
      项目组电话：010-56218127；010-56218263；010-56218232（周一到周五，节假日除外，9:00-18:00）
      <br />项目组邮箱：service@thussat.com
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button class="reading" @click="cancelBtn()">我已阅读并知晓 </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { cancelOrder } from "r/index/order.js";
export default {
  name: "importNotice",
  props: {
    noticeDialogFu: Boolean,
  },
  data() {
    return {
      dialogVisible: this.noticeDialogFu,
    };
  },
  methods: {
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeCSUDialog", this.dialogVisible, false);
    },
  },
  watch: {
    noticeDialogFu() {
      this.dialogVisible = this.noticeDialogFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
.info_2 {
  margin: 10px 0;
}
.aa {
  padding: 5px 12px;
}
.isinfo {
  font-size: 12px;
  color: #e0823d;
  height: 20px;
  line-height: 20px;
}
.bk {
  background-color: #f5f5f5;
  border-radius: 4px;
}
::v-deep .el-dialog {
  .el-dialog__header {
    height: 65px;

    margin-top: 18px;
  }
  .el-dialog__body {
    padding: 10px 40px 0 40px;
  }
}
::v-deep .el-dialog__close {
  display: none;
}

::v-deep .el-dialog__footer {
  padding: 30px 40px 60px 30px !important;
  text-align: center;
  .reading {
    background-color: #563279;
    color: #ffffff;
  }
  .el-button {
    height: 45px !important;
    padding: 0 54px !important;
  }
}
</style>

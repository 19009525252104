<template>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="520px"
            top="0"
            :before-close="cancelBtn"
            :close-on-click-modal="false"
    >
        <div class="info_2">
            根据疫情形势及相关防控要求，为最大限度保障参试考生的安全及考试的严肃性，项目组决定：<br/>
            为北京市、上海市、吉林省、广东省中山市考生（包括选择该考点城市的外地考生），部分来自中高风险地区或在考试开始前14天有中高风险地区所在城市旅居史的考生，以及其他不符合国家防疫要求的考生安排考试延期至8月TACA综合测试（拟于8月27日举行）或取消报名。<br/>
            请在本系统<span>“查看报名信息”</span>页面选择延期或者取消报名。对于取消报名的考生，项目组会在考试结束五个工作日内发起退费，请注意查收。
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
            <el-button class="confirm" @click="confirm()">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        name: "postPoneDialog",
        props: {
            postPoneDialogFu: Boolean,
        },
        data() {
            return {
                dialogVisible: this.postPoneDialogFu,
            };
        },
        methods: {
            async confirm() {
                this.dialogVisible = false;
                this.$emit("closePPDialog", this.dialogVisible);
            },
            // 取消,关闭弹框，修改父组件的值
            cancelBtn() {
                this.dialogVisible = false;
                this.$emit("closePPDialog", this.dialogVisible);
            },
        },
        watch: {
            postPoneDialogFu() {
                this.dialogVisible = this.postPoneDialogFu;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../../assets/scss/dialog";
    .info_2 {
        span {
            color: #D18635;
        }
    }
</style>

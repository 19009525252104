import { render, staticRenderFns } from "./SafetyCommit.vue?vue&type=template&id=3039bd93&scoped=true&"
import script from "./SafetyCommit.vue?vue&type=script&lang=js&"
export * from "./SafetyCommit.vue?vue&type=script&lang=js&"
import style0 from "./SafetyCommit.vue?vue&type=style&index=0&id=3039bd93&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3039bd93",
  null
  
)

export default component.exports
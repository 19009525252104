<template>
  <el-dialog
    title="认证成绩单"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_2">
      请扫码关注THUSSAT官网服务号，点击底部菜单“服务-查询及认证”获取认证成绩单。
    </div>
    <div style="margin:10px auto;text-align: center;" >
        <img src="../../../assets/images/fwh.png"/>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "RecordPaper",
  props: {
    recordDialogFu: Boolean,
  },
  data() {
    return {
      dialogVisible: this.recordDialogFu,
    };
  },
  methods: {
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeRecordDialog", this.dialogVisible);
    },
  },
  watch: {
    recordDialogFu() {
      this.dialogVisible = this.recordDialogFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
</style>
